const platformZone = document.querySelector('meta[name="platform-zone"]')?.content
  || navigator.language.split('-')[0];

document.addEventListener('DOMContentLoaded', () => {
  i18next.init({
    lng: platformZone,
    resources: {
      en: {
        translation: {
          "За отправленные сообщения": "Pay per Message",
          "За количество подписчиков": "Pay per Client",
          "Доступное количество сообщений": "Choose messages amount",
          "Доступное количество подписчиков": "Choose clients amount",
          "Количество email-писем": "Choose bulk emails amount",
          "period": "Choose time period",
          "unlimited": "unlimited",
          "economy": "Savings:",
          "сообщение в день": "texts per day",
          "сообщения в день": "texts per day",
          "сообщений в день": "texts per day",
          "подписчик": "contacts",
          "подписчика": "contacts",
          "подписчиков": "contacts",
          "письмо в месяц": "emails per month",
          "письма в месяц": "emails per month",
          "писем в месяц": "emails per month",
          "месяц": "month",
          "месяца": "months",
          "месяцев": "months",
          "price-for": "price for",
          "month": "month",

        }
      },
      ru: {
        translation: {
          "welcome": "Добро пожаловать",
          "hello_world": "Привет, мир!",
          "period": "Период",
          "unlimited": "неограниченно",
          "economy": "Экономия:",
          "price-for": "цена за",
          "month": "мес.",
        }
      }
    }
  }).then(() => {
    updateTranslations();
  });
});

function updateTranslations() {
  document.querySelectorAll('[data-i18n]').forEach(element => {
    const key = element.getAttribute('data-i18n');
    console.log({key})
    element.innerHTML = i18next.t(key);
  });
}

function calcProgressValue(current, min, max) {
  let steps = Math.abs(max - min);
  let progressValue = 100 / steps * (current - min);

  progressValue = Math.min(100, progressValue);
  progressValue = Math.max(0, progressValue);
  return progressValue;
}

window.initSelectorsWithDelay = function (delay_ms = 200) {
  setTimeout(function () {
    initSelectors();
  })
}

function initSelectors() {
  for (let selectorWrapper of document.getElementsByClassName("selector__wrapper")) {
    let slider = selectorWrapper.getElementsByClassName("selector__input")[0];
    let selector = selectorWrapper.getElementsByClassName("selector")[0];
    // let hint = selector.getElementsByClassName("selector__hint")[0];
    let progress = selectorWrapper.getElementsByClassName("selector__filled")[0];


    // hint.innerHTML = slider.value;
    let progressValue = calcProgressValue(slider.value, slider.min, slider.max)
    selectorLeft = Math.min(progressValue, 98);
    selectorLeft = Math.max(selectorLeft, 2);
    selector.style.left = selectorLeft + "%";
    progress.style.width = progressValue + "%";

    slider.oninput = function () {
      let progressValue = calcProgressValue(this.value, this.min, this.max)
      // hint.innerHTML = this.value;
      selectorLeft = Math.min(progressValue, 98);
      selectorLeft = Math.max(selectorLeft, 2);
      selector.style.left = selectorLeft + "%";
      progress.style.width = progressValue + "%";
    }
  }
}

//initSelectors();
function thatsTest2() {
  alert("tt2");
}

function thatsTest() {
  thatsTest2();
}

window.q = thatsTest;

window.plansComponent = function () {

  return {
    plans: [],
    updatePlans(callback = null) {
      fetch(`/landing/plans`)
        .then(res => res.json())
        .then(data => {
          this.isLoading = false;
          this.plans = data;

          data.forEach(function (plan) {
            plan.current_months = 1;

            plan.groups.forEach(function (group) {
              group.current_feature = 0;
              if (plan.name == "messages" && group.name == "messages")
                group.current_feature = 1;
            })
          });

          console.log({data})

          setTimeout(() =>
              updateTranslations(),
            50
          )

          if (typeof callback == "function")
            callback()
        });

    },
    localize(str) {
      console.log({
        "localize": 1, str
      })
      return i18next.t(str)
    },
    localizeArray(arr) {
      return arr.map(el => {
        return this.localize(el);
      });
    },
    makeCaption(count, titleArray) {
      const num = parseFloat(count);
      let digits = num;

      if (num >= 1000000000)
        digits = this.localize("unlimited");
      else
        digits = num.toLocaleString(platformZone)

      const localizedTitleArray = this.localizeArray(titleArray);

      console.log({localizedTitleArray})

      let caption = this.skl(count, localizedTitleArray);
      return digits + " " + caption;
    },
    skl(num, titleArray) {
      const cases = [2, 0, 1, 1, 1, 2];

      if (titleArray.length < 3)
        return titleArray[0] || titleArray;
      else
        return titleArray[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];

      return digits + " " + caption;
    },
    abbreviatedNumber(number, fixed = 0) {
      if (number === null || number === 0) {
        return number;
      }

      let num = parseFloat(number);
      if (isNaN(num))
        return number;

      if (num > 1000000000) return "∞";

      fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show

      let b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'k', 'm', 'b', 't'][k]; // append power
      return e;
    },
    humanNumber(num, smallTail = true) {
      num = Math.round(num * 100) / 100;
      num += '';
      num = num.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
      if (smallTail)
        num = num.replace(/(\d+?)( [\d \.\,]+)/g, "$1<span style='font-size: .85em'>$2</span>");
      return num;
    },
    calculatePrices(plan) {
      let monthPrice = plan.price;
      plan.groups.forEach(function (group) {
        monthPrice += group.features[group.current_feature].feature_price
      });

      let period = plan.current_months;
      let discountPercent = this.calculateDiscountByPeriod(period);
      let discountedMonthPrice = Math.max((monthPrice - monthPrice * this.calculateDiscountByPeriod(period)), 0);
      let discountedTotalPrice = discountedMonthPrice * period;
      let totalPrice = monthPrice * period;
      let totalDiscount = totalPrice - discountedTotalPrice;


      return {
        monthPrice: monthPrice,
        discountedMonthPrice: discountedMonthPrice,
        discountedTotalPrice: discountedTotalPrice,
        totalPrice: totalPrice,
        totalDiscount: totalDiscount,
        discountPercent: discountPercent,
      }
    },
    calculateDiscountByPeriod(period) {
      let discount = 0;
      if (period >= 3) discount = .05;
      if (period >= 6) discount = .10;
      if (period >= 12) discount = .15;
      return discount;
    },
  }

}
