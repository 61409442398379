import $ from "jquery"

$("body").on("click", `.header-mob a[href*="#"]`, function (e) {
  const fixed_offset = 30;
  $("html,body")
    .stop()
    .animate({
      scrollTop: $(this.hash).offset().top - fixed_offset
    }, 1000);

  $(this).parents(".modal").modal("hide");
  e.preventDefault();
});
